// Language loading
import enFlag from "../images/flags/en.png";
import esFlag from "../images/flags/es.png";
import caFlag from "../images/flags/ca.png";
import itFlag from "../images/flags/it.png";
import ruFlag from "../images/flags/ru.png";

export const supportedLanguages = [
  { code: "ca", name: "Catala", flag: caFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "es", name: "Español", flag: esFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "en-gb", name: "English", flag: enFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "it", name: "Italiano", flag: itFlag, route: "/simpleHome", showSimplified: true, simp:"Semplificato" },
  { code: "ru", name: "Русский", flag: ruFlag, route: "/simpleHome", showSimplified: true, simp:"Упрощенная версия" },
  //{ code: "de", name: "Deutsche", flag: deFlag, route: "/simpleHome" },
  // Add more supported languages as needed
];


// Park prices
const adultPrice = 12;
const kidsPrice = Math.round(adultPrice * 0.7 * 2) / 2;
const retiredPrice = Math.round(adultPrice * 0.75 * 2) / 2;
const yearPassMultiplication = 3.5;
const yearSeasonalMultiplication = 2;
const workshop = 9;

export const prices = {
  year: 2025,
  booking: 40,
  adult: adultPrice,
  adultYearPass: Math.ceil(adultPrice * yearPassMultiplication),
  adultSeasonalPass: Math.ceil(adultPrice * yearSeasonalMultiplication),
  kids: kidsPrice,
  kidsYearPass: Math.ceil(kidsPrice * yearPassMultiplication),
  kidsSeasonalPass:Math.ceil(kidsPrice * yearSeasonalMultiplication),
  toddler: 0,
  retired: retiredPrice,
  retiredYearPass: Math.ceil(retiredPrice * yearPassMultiplication),
  retiredSeasonalPass:Math.ceil(retiredPrice * yearSeasonalMultiplication ),
  teacher: adultPrice * 0.85,
  disabledA: adultPrice * 0.75,
  disabledK: kidsPrice * 0.75,
  guide: 0.75,
  donkey: 1,
  golf: 2,
  chicken: 0.5,
  tour: 0.5,
  workshop: workshop,
  workshopBundle: workshop * 2,
};

// Calendar Colors
export const colors = [
  "white",
  "#6ba37e",
  "#ffe438",
  "#ee3434",
  "#2DA8B8",
  "#CC66CC",
  "pink",
  'gray',
  "orange"
];
  
// List of seasons with start and end dates
export const seasons = [

  //2025
  {
    start: "2025-03-08",
    end: "2025-05-26",
    season: "low",
  },
  {
    start: "2025-05-26",
    end: "2025-06-23",
    season: "highstart",
  },
  {
    start: "2025-06-23",
    end: "2025-09-08",
    season: "high",
  },
  {
    start: "2025-09-15",
    end: "2025-11-24",
    season: "low",
  },
  {
    start: "2025-11-24",
    end: "2025-12-26",
    season: "lowend",
  },
   {
     start: "2026-03-09",
     end: "2026-12-31",
     season: "comingsoon",
   }
];

// List of special days that we are closed/open. Format yyyy-mm-dd
export const special = [
  // 2025
  { date: "2025-03-08", start: false }, // Not open yet
  { date: "2025-03-09", start: false }, // Not open yet
  //{ date: "2025-03-10", start: false }, // Not open yet
  { date: "2025-04-05", start: false }, // Assembly
  { date: "2025-04-14", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-04-15", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-04-16", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-04-17", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-04-18", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-04-21", start: "10:30", end: "17:00" }, // Easter
  { date: "2025-05-01", start: "10:30", end: "17:00" }, // Dia del trabajador
  { date: "2025-06-09", start: "10:30", end: "18:00" }, // Segunda pascua
  { date: "2025-06-23", start: "10:30", end: "18:00" }, // Puente
  { date: "2025-06-24", start: "10:30", end: "18:00" }, // San Juan
  { date: "2025-07-04", start: false }, // Assembly
  { date: "2025-07-05", start: false }, // Assembly
  { date: "2025-07-06", start: false }, // Assembly
  { date: "2025-09-23", start: "10:30", end: "17:00" }, // Santa Tecla (Tarragona)
  { date: "2025-09-24", start: "10:30", end: "17:00" }, // La Merce
  { date: "2025-09-25", start: "10:30", end: "17:00" }, // Puente
  { date: "2025-09-26", start: "10:30", end: "17:00" }, // Barcelona local holiday
  { date: "2025-11-01", start: "10:30", end: "17:00" }, // All Saints day
  { date: "2025-12-08", start: "11:00", end: "16:00" }, // Immaculate Conception
  { date: "2025-12-25", start: "11:00", end: "16:00" }, // Christmas
  { date: "2025-12-26", start: "11:00", end: "16:00" }, // Boxing day
];

//  List of time tables
export const times: {
  [key: string]: {
    [key: string]:
    | boolean
    | { start: string; end: string; tourAM: string; tourPM?: string }
    | string;
  };
} = {
  low: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00"  },
    },
    lowend: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Sun: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Holiday: { start: "11:00", end: "16:00", tourAM: "11:30" },
    },
    highstart: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00" },
    },
    high: {
      Mon: false, //closed,
      Tue: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Wed: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Thu: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Fri: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "18:00", tourAM: "11:00" },
    },
};