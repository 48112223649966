import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Close, PendingActions } from "@mui/icons-material";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../data/data";
import { getWorkshopData } from "../../components/Workshop/WorkshopTimetable";
import { Link } from "react-router-dom";

export default function WorkshopPopup() {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [eventPopupOpen, setEventPopupOpen] = useState(false);

  // Create a ref for the Box element
  const boxRef = useRef<HTMLDivElement | null>(null); // Explicitly typing the ref

  // Get today's date in YYYY-MM-DD format
  const date = new Date();
  const normalizedDate = date.toLocaleDateString("en-CA");

  // Get the date X month from today
  const xMonthLater = new Date();
  xMonthLater.setMonth(xMonthLater.getMonth() + 2);
  const normalizedOneMonthLater = xMonthLater.toLocaleDateString("en-CA");

  // Get the list of workshops
  const workshopData = getWorkshopData();

  // Find the next available workshop within the next X month
  const nextWorkshop = workshopData
    .flatMap((workshop) =>
      workshop.dates.map((dateObj) => ({
        ...dateObj,
        title: workshop.title, // Include title
      }))
    )
    .filter(
      (workshop) =>
        workshop.date >= normalizedDate &&
        workshop.date <= normalizedOneMonthLater
    ) // Only workshops in the next X month
    .sort((a, b) => a.date.localeCompare(b.date))[0]; // Sort by date // Get the first upcoming workshop

  useEffect(() => {
    // Initialize state to { isOpen: false, isMinimized: true } initially
    const initialState = { isOpen: false, isMinimized: true };
    setIsOpen(initialState.isOpen);
    setIsMinimized(initialState.isMinimized);

    // Store the initial state in localStorage
    localStorage.setItem("workshopPopupState", JSON.stringify(initialState));

    // Timer to change the state after 15 seconds
    const timer = setTimeout(() => {
      const newState = { isOpen: true, isMinimized: false }; // After 15 seconds, make it open and not minimized
      setIsOpen(newState.isOpen);
      setIsMinimized(newState.isMinimized);

      // Store the updated state in localStorage
      localStorage.setItem("workshopPopupState", JSON.stringify(newState));
    }, 15000); // 15 seconds delay

    const updateState = () => {
      const savedStateString = localStorage.getItem("workshopPopupState");
      const savedState = savedStateString
        ? JSON.parse(savedStateString)
        : { isOpen: true, isMinimized: false }; // Set default values

      setIsOpen(savedState.isOpen);
      setIsMinimized(savedState.isMinimized);

      const eventStateString = localStorage.getItem("eventPopupState");
      const eventState = eventStateString ? JSON.parse(eventStateString) : {}; // Add null check

      setEventPopupOpen(eventState.isOpen || false);
    };

    updateState();

    const handleStorageChange = () => updateState();
    window.addEventListener("storage", handleStorageChange);

    const interval = setInterval(updateState, 500);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(interval);
    };
  }, []);

  const handleClose = () => {
    setIsMinimized(true);
    localStorage.setItem(
      "workshopPopupState",
      JSON.stringify({ isOpen: false, isMinimized: true })
    );
  };

  const handleReopen = () => {
    setIsMinimized(false);
    setIsOpen(true);
    localStorage.setItem(
      "workshopPopupState",
      JSON.stringify({ isOpen: true, isMinimized: false })
    );
  };

  // Save the box height to localStorage
  useEffect(() => {
    if (boxRef.current) {
      const boxHeight = boxRef.current.clientHeight;
      console.log("Workshop box height:", boxHeight);

      // Save the height to localStorage
      localStorage.setItem("workshopBoxHeight", JSON.stringify(boxHeight));
    }
  }, [isOpen, isMinimized]); // Runs when the popup's state changes

  return (
    <Box>
      {isOpen && !isMinimized && nextWorkshop && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}>
          <Box
            ref={boxRef} // Attach the ref to the Box element
            textAlign={"center"}
            sx={{
              position: "fixed",
              bottom: eventPopupOpen
                ? { xs: 55, sm: 10 }
                : { xs: 165, sm: 115 }, // Move up if Event Popup is open
              right: { xs: 5, sm: 10 },
              backgroundColor: "white",
              boxShadow: 3,
              borderRadius: 2,
              p: 1,
              width: 300,
              maxWidth: "75vw",
              border: "1px solid #ccc",
              zIndex: 8001,
            }}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              borderBottom={1}>
              <Typography variant='h6'>{t("menu.workshop")}</Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant='body2' mt={2}>
              {t("popups.workshop.desc")}
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: 800, padding: 0.5 }}>
              {t(nextWorkshop.title)}
            </Typography>
            <Link to={`/${i18n.language}/workshop`} onClick={handleClose}>
              <Button
                variant='contained'
                color='warning'
                sx={{ mt: 2, backgroundColor: colors[8] }}>
                {t("popups.workshop.button")}
              </Button>
            </Link>
          </Box>
        </motion.div>
      )}

      {isMinimized && (
        <Box
          sx={{
            position: "fixed",
            bottom: { xs: 55, sm: 5 },
            right: 0,
            height: 48,
            width: "auto",
            padding: 1,
            backgroundColor: colors[8],
            borderRadius: "24px 0 0 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            zIndex: 8000,
            boxShadow: 3,
          }}
          onClick={handleReopen}>
          <PendingActions />
          <Typography variant='body2'>{t("menu.workshop")}</Typography>
        </Box>
      )}
    </Box>
  );
}
