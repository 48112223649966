import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const FrogIcon = () => (
    <SvgIcon viewBox='0 -32 576 576'>
    <path d='M446.53 97.43C439.67 60.23 407.19 32 368 32c-39.23 0-71.72 28.29-78.54 65.54C126.75 112.96.5 250.12 0 416.98.11 451.9 29.08 480 64 480h304c8.84 0 16-7.16 16-16 0-17.67-14.33-32-32-32h-79.49l35.8-48.33c24.14-36.23 10.35-88.28-33.71-106.6-23.89-9.93-51.55-4.65-72.24 10.88l-32.76 24.59c-7.06 5.31-17.09 3.91-22.41-3.19-5.3-7.08-3.88-17.11 3.19-22.41l34.78-26.09c36.84-27.66 88.28-27.62 125.13 0 10.87 8.15 45.87 39.06 40.8 93.21L469.62 480H560c8.84 0 16-7.16 16-16 0-17.67-14.33-32-32-32h-53.63l-98.52-104.68 154.44-86.65A58.16 58.16 0 0 0 576 189.94c0-21.4-11.72-40.95-30.48-51.23-40.56-22.22-98.99-41.28-98.99-41.28zM368 136c-13.26 0-24-10.75-24-24 0-13.26 10.74-24 24-24 13.25 0 24 10.74 24 24 0 13.25-10.75 24-24 24z' />
  </SvgIcon>
);

export const ButterflyIcon = () => (
    <SvgIcon viewBox='0 0 512 512'>
    <path d='M243.695,179.339c0.703,4.906,5.813,7.438,7.719,1.406c1.891-6.031-4.828-17.219-22.219-36.531 c-14.828-16.484-35.625-39.391-23.844-51.578c14.609-10.078,8.469-27.75-4.172-29.469c-11.313-1.516-21.609,13.578-15.031,38.703 C192.711,126.964,241.695,165.292,243.695,179.339z' />
    <path d='M445.898,83.886c-74.469,0-160.703,89.859-174.516,111.078c-3.594-4.578-9.109-7.578-15.375-7.578 c-6.281,0-11.797,3-15.391,7.578C226.805,173.73,140.57,83.886,66.102,83.886c-76.828,0-70.547,68.984-59.578,112.891 c10.969,43.922,56.453,92.516,106.609,94.094c-56.438,25.078-61.141,89.375-43.891,119.156 c16.359,28.25,103.266,92.016,167.156-50.296v29.141c0,10.813,8.781,19.593,19.609,19.593c10.813,0,19.594-8.781,19.594-19.593 v-29.156c63.891,142.328,150.813,78.562,167.156,50.312c17.25-29.781,12.547-94.078-43.891-119.156 c50.172-1.578,95.641-50.172,106.609-94.094C516.445,152.871,522.727,83.886,445.898,83.886z' />
    <path d='M268.305,179.339c2-14.047,50.984-52.375,57.563-77.469c6.563-25.125-3.734-40.219-15.047-38.703 c-12.641,1.719-18.766,19.391-4.172,29.469c11.781,12.188-9.016,35.094-23.844,51.578c-17.391,19.313-24.109,30.5-22.219,36.531 C262.492,186.777,267.602,184.246,268.305,179.339z' />
  </SvgIcon>
);
        
export const BatIcon = () => (
    <SvgIcon viewBox='0 0 512 512'>
    <path d='M372.084,106.642c9.277,64.285-67.483,102.934-76.724,103.858c-15.122,1.498-2.291-20.244,0.566-43.667 c2.857-23.414-22.813,21.742-39.922,21.742c-17.108,0-42.778-45.156-39.93-21.742c2.858,23.423,15.688,45.165,0.566,43.667 c-9.242-0.923-86.001-39.573-76.724-103.858C79.202,131.363-9.325,265.891,0.797,276.762 c10.122,10.889,57.334-15.827,88.527,53.424c28.293-26.785,62.865-20.845,80.104,40.558c56.507-40.035,74.747,16.62,86.576,34.616 c11.821-17.996,30.061-74.65,86.568-34.616c17.238-61.402,51.812-67.343,80.112-40.558c31.194-69.251,78.406-42.535,88.519-53.424 C521.324,265.891,432.797,131.363,372.084,106.642z' />
    </SvgIcon>
);

export const BirdIcon = () => (
  <SvgIcon viewBox='0 0 256 256'>
    <path
      d='M176,72a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,176,72Zm68,8a11.998,11.998,0,0,1-5.34375,9.98438L220,102.42188V120A108.12217,108.12217,0,0,1,112,228H12a12,12,0,0,1-9.52344-19.30127L92,91.92969V76A64.01009,64.01009,0,0,1,216.5752,55.294l22.08105,14.72167A11.99924,11.99924,0,0,1,244,80Zm-33.63281,0-10.69043-7.12793a12.001,12.001,0,0,1-4.958-6.9668A40.00762,40.00762,0,0,0,116,76V96a11.9995,11.9995,0,0,1-2.47656,7.30127L36.32129,204H112a84.0953,84.0953,0,0,0,84-84V96a11.99859,11.99859,0,0,1,5.34375-9.98438Zm-81.7334,46.47656a11.99957,11.99957,0,0,0-16.82422,2.22266l-30.666,40a11.99989,11.99989,0,1,0,19.04688,14.60156l30.666-40A11.99975,11.99975,0,0,0,128.63379,126.47656Z'
    />
  </SvgIcon>
);

export const AnimalIcon = () => (
  <SvgIcon viewBox='0 0 32 32'>
    <path
      d='M23.368 22.192c4.552 1.705 2.249 6.725-1.729 6.709-3.116-0.013-3.287-1.633-5.416-1.642-1.875-0.008-2.691 1.798-4.981 1.789-4.173-0.017-7.125-5.008-2.25-6.664 6.389-2.171 3.453-5.931 7.275-5.893 3.732 0.037 1.306 3.532 7.101 5.703l0 0zM19.769 14.166c3.246-0.13 3.252-7.413-0.162-7.275-3.47 0.141-3.14 7.408 0.162 7.275zM13.318 14.274c3.235-0.278 2.852-7.552-0.55-7.259-3.458 0.298-2.74 7.542 0.55 7.259zM8.334 19.46c3.219-0.406 2.495-7.659-0.89-7.23-3.441 0.436-2.384 7.644 0.89 7.23zM24.794 19.566c3.248 0.062 3.76-7.206 0.344-7.27-3.473-0.064-3.648 7.207-0.344 7.27zM6.442 10.684c1.864-0.156 1.116-2.53-0.413-4.316-1.044 2.054-1.1 4.442 0.413 4.316zM25.825 10.513c-1.865-0.024-1.319-2.453 0.051-4.347 1.207 1.981 1.462 4.367-0.051 4.347zM20.008 5.555c-1.851 0.207-1.542-2.282-0.367-4.341 1.387 1.826 1.869 4.173 0.367 4.341zM12.608 5.784c-1.838 0.289-1.66-2.211-0.593-4.32 1.48 1.762 2.085 4.085 0.593 4.32z'
    />
  </SvgIcon>
);

export const LeafIcon = () => (
  <SvgIcon viewBox="0 0 24 24">
    <path
      d="M4.44893 17.009C-0.246384 7.83762 7.34051 0.686125 19.5546 3.61245C20.416 3.81881 21.0081 4.60984 20.965 5.49452C20.5862 13.288 17.0341 17.7048 6.13252 17.9857C5.43022 18.0038 4.76908 17.6344 4.44893 17.009ZM3.99999 21C5.50005 15.5 6 12.5 12 9.99997"
    />
  </SvgIcon>
);

export const BirdFlyIcon = () => (
  <SvgIcon viewBox="0 0 512 512">
    <path d="M104.683,31.445l-15.34,20.28c-37.858,50.044-23.056,112.53-15.045,136.852c-4.476,7.514-10.274,19.053-13.956,33.246 c-7.008,27.02-3.65,52.862,9.642,75.801c0.994-0.368,1.986-0.742,2.968-1.121c51.982-21.743,125.655-80.793,155.274-105.48 L104.683,31.445z"></path>
    <path d="M349.594,306.907c53.594-3.88,96.023-48.695,96.023-103.263c0-14.023-2.815-27.397-7.887-39.605l36.521-15.837 l-43.501-17.186l-0.868-46.764l-31.421,32.606c-16.243-10.596-35.615-16.781-56.413-16.781 c-57.106,0-103.567,46.46-103.567,103.567c0,9.165,1.21,18.05,3.456,26.52c-35.909,29.27-103.428,81.248-154.464,102.448 C47.102,348.313,0,355.04,0,355.04l75.138,26.915L31.4,455.973c0,0,76.958-38.002,167.035-44.049 c5.956,16.166,16.113,30.88,29.872,42.843c19.649,17.086,42.161,25.791,66.18,25.789c8.861,0,17.932-1.186,27.136-3.573 c14.192-3.68,25.732-9.48,33.246-13.956c24.322,8.013,86.808,22.812,136.852-15.045L512,432.643L349.594,306.907z M364.526,174.517c10.748,0,19.459,8.712,19.459,19.459c0,10.746-8.711,19.459-19.459,19.459 c-10.746,0-19.459-8.712-19.459-19.459C345.068,183.229,353.78,174.517,364.526,174.517z"></path>
  </SvgIcon>
);
