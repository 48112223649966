import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Close, PendingActions } from "@mui/icons-material";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../data/data";
import { getEventData } from "../Events/EventsTimetable";
import { Link } from "react-router-dom";

export default function EventPopup() {
  const { t, i18n } = useTranslation();

  // Explicitly typing the ref to be a HTMLDivElement (or Box component in this case)
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [workshopPopupOpen, setWorkshopPopupOpen] = useState(false);

  // Get today's date in YYYY-MM-DD format
  const date = new Date();
  const normalizedDate = date.toLocaleDateString("en-CA");

  // Get the date X month from today
  const xMonthLater = new Date();
  xMonthLater.setMonth(xMonthLater.getMonth() + 2);
  const normalizedOneMonthLater = xMonthLater.toLocaleDateString("en-CA");

  // Get the list of events
  const eventData = getEventData();

  // Find the next available event within the next X month
  const nextEvent = eventData
    .flatMap((event) =>
      event.dates.map((dateObj) => ({
        ...dateObj,
        title: event.title,
        color: event.bgColor,
      }))
    )
    .filter(
      (event) =>
        event.date >= normalizedDate && event.date <= normalizedOneMonthLater
    )
    .sort((a, b) => a.date.localeCompare(b.date))[0];

  useEffect(() => {
    // Store the initial state in localStorage
    const initialState = { isOpen: false, isMinimized: true };
    localStorage.setItem("eventPopupState", JSON.stringify(initialState));

    const timer = setTimeout(() => {
      const newState = { isOpen: true, isMinimized: false };
      setIsOpen(newState.isOpen);
      setIsMinimized(newState.isMinimized);
      localStorage.setItem("eventPopupState", JSON.stringify(newState));
    }, 15000); // 15 seconds delay

    const updateState = () => {
      const savedStateString = localStorage.getItem("eventPopupState");
      const savedState = savedStateString
        ? JSON.parse(savedStateString)
        : { isOpen: false, isMinimized: true };

      setIsOpen(savedState.isOpen);
      setIsMinimized(savedState.isMinimized);

      const workshopStateString = localStorage.getItem("workshopPopupState");
      const workshopState = workshopStateString
        ? JSON.parse(workshopStateString)
        : {};

      setWorkshopPopupOpen(workshopState.isOpen || false);
    };

    updateState();
    const handleStorageChange = () => updateState();
    window.addEventListener("storage", handleStorageChange);
    const interval = setInterval(updateState, 500);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(interval);
    };
  }, []);

  // Get the stored height of the workshop popup
  const savedHeight = localStorage.getItem("workshopBoxHeight");
  const workshopBoxHeight = savedHeight ? JSON.parse(savedHeight) : 0;

  const handleClose = () => {
    setIsMinimized(true);
    localStorage.setItem(
      "eventPopupState",
      JSON.stringify({ isOpen: false, isMinimized: true })
    );
  };

  const handleReopen = () => {
    setIsMinimized(false);
    setIsOpen(true);
    localStorage.setItem(
      "eventPopupState",
      JSON.stringify({ isOpen: true, isMinimized: false })
    );
  };

  return (
    <Box>
      {isOpen && !isMinimized && nextEvent && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}>
          <Box
            ref={boxRef} // Attach the ref to the Box element
            textAlign={"center"}
            sx={{
              position: "fixed",
              bottom: workshopPopupOpen
                ? { xs: workshopBoxHeight + 60, sm: workshopBoxHeight + 20 } // Dynamically adjust bottom
                : { xs: 110, sm: 60 },
              right: { xs: 5, sm: 10 },
              backgroundColor: "white",
              boxShadow: 3,
              borderRadius: 2,
              p: 1,
              width: 300,
              maxWidth: "75vw",
              border: "1px solid #ccc",
              zIndex: 8001,
            }}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              borderBottom={1}>
              <Typography variant='h6'>{t("menu.event")}</Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant='body2' mt={2}>
              {t("popups.event.desc")}
            </Typography>
            <Typography
              textAlign={"center"}
              variant='body1'
              sx={{
                fontWeight: 800,
                background: nextEvent.color,
                padding: 0.5,
              }}>
              {t(nextEvent.title)}
            </Typography>
            <Link to={`/${i18n.language}/events`} onClick={handleClose}>
              <Button
                variant='contained'
                color='secondary'
                sx={{ mt: 2, backgroundColor: nextEvent.color }}>
                {t("popups.event.button")}
              </Button>
            </Link>
          </Box>
        </motion.div>
      )}

      {isMinimized && (
        <Box
          sx={{
            position: "fixed",
            bottom: { xs: 110, sm: 60 },
            right: 0,
            height: 48,
            width: "auto",
            padding: 1,
            backgroundColor: colors[6],
            borderRadius: "24px 0 0 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            zIndex: 8000,
            boxShadow: 3,
          }}
          onClick={handleReopen}>
          <PendingActions />
          <Typography variant='body2'>{t("menu.event")}</Typography>
        </Box>
      )}
    </Box>
  );
}
