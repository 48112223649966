import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationCA from './locales/ca.json';
import translationIT from './locales/it.json';
import translationRU from './locales/ru.json';
//import translationDE from './locales/de.json';

// Check local storage for the selected language
const storedLanguage = localStorage.getItem("language");

// Extract the language code from the URL
const languageCodeFromURL = window.location.pathname.split("/")[1]; // Assuming the language code is the first segment of the path

// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    'en': {
      translation: {
        ...translationEN,
      },
    },
    es: {
      translation: {
        ...translationES,
      },
    },
    ca: {
      translation: {
        ...translationCA,
      },
    },
    it: {
      translation: {
        ...translationIT,
      },
    },
    ru: {
      translation: {
        ...translationRU,
      },
    }
  },
  lng: languageCodeFromURL || storedLanguage || 'es', // Use the stored language if available, otherwise default to 'es'
  fallbackLng: 'es', // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // Disable escaping of special characters
  },
});

export default i18n;
