import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Typography, Grid, Button, colors } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AnimalIcon,
  BatIcon,
  BirdFlyIcon,
  BirdIcon,
  FrogIcon,
  LeafIcon,
} from "../../data/svgIcons";
import images from "../../data/images";
import { format } from "date-fns";
import BugReportIcon from "@mui/icons-material/BugReport";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

interface EventDate {
  date: string;
}

interface Event {
  title: string;
  dates: EventDate[];
  description: string;
  icon: JSX.Element;
  imageUrl: string;
  bgColor: string;
}

// Static events array
const staticEvents: Event[] = [
  {
    title: "events.timetable.frogDay.title",
    dates: [{ date: "26-04-2025" }, { date: "27-04-2025" }],
    description: "events.timetable.frogDay.description",
    icon: <FrogIcon />,
    imageUrl: images.pond,
    bgColor: colors.green[600],
  },
  {
    title: "events.timetable.birdDay.title",
    dates: [{ date: "17-05-2025" }, { date: "18-05-2025" }],
    description: "events.timetable.birdDay.description",
    icon: <BirdIcon />,
    imageUrl: images.birds,
    bgColor: colors.blue[300],
  },
  {
    title: "events.timetable.insectDay.title",
    dates: [{ date: "14-06-2025" }, { date: "15-06-2025" }],
    description: "events.timetable.insectDay.description",
    icon: <BugReportIcon />,
    imageUrl: images.insectDay,
    bgColor: colors.brown[300],
  },
  {
    title: "events.timetable.animalDay.title",
    dates: [
      { date: "18-07-2025" },
      { date: "19-07-2025" },
      { date: "20-07-2025" },
    ],
    description: "events.timetable.animalDay.description",
    icon: <AnimalIcon />,
    imageUrl: images.animalDay,
    bgColor: colors.grey[300],
  },
  {
    title: "events.timetable.batDay.title",
    dates: [
      { date: "15-08-2025" },
      { date: "16-08-2025" },
      { date: "17-08-2025" },
    ],
    description: "events.timetable.batDay.description",
    icon: <BatIcon />,
    imageUrl: images.batDay,
    bgColor: colors.red[300],
  },
  {
    title: "events.timetable.treeDay.title",
    dates: [{ date: "20-09-2025" }, { date: "21-09-2025" }],
    description: "events.timetable.treeDay.description",
    icon: <LeafIcon />,
    imageUrl: images.organicDay,
    bgColor: colors.green[300],
  },
  {
    title: "events.timetable.migratoryDay.title",
    dates: [{ date: "18-10-2025" }, { date: "19-10-2025" }],
    description: "events.timetable.migratoryDay.description",
    icon: <BirdFlyIcon />,
    imageUrl: images.migratoryDay,
    bgColor: colors.yellow[300],
  },
  {
    title: "events.timetable.natureDay.title",
    dates: [{ date: "15-11-2025" }, { date: "16-11-2025" }],
    description: "events.timetable.natureDay.description",
    icon: <AnimalIcon />,
    imageUrl: images.natureDay,
    bgColor: colors.lime[300],
  },
];

const groupByDateRanges = (events: Event[]) => {
  return events.flatMap((event) => {
    const sortedDates = [...event.dates]
      .map(({ date }) => new Date(date.split("-").reverse().join("-")))
      .sort((a, b) => a.getTime() - b.getTime());

    const dateRanges = [];
    let start = sortedDates[0];
    let end = start;

    for (let i = 1; i < sortedDates.length; i++) {
      const current = sortedDates[i];
      if (current.getTime() - end.getTime() === 86400000) {
        end = current;
      } else {
        dateRanges.push({ start, end });
        start = current;
        end = current;
      }
    }
    dateRanges.push({ start, end });

    return dateRanges.map(({ start, end }) => ({
      ...event,
      dateRange: {
        start: format(start, "dd-MM-yyyy"),
        end: format(end, "dd-MM-yyyy"),
      },
    }));
  });
};

export const getEventData = () =>
  staticEvents.map(({ title, dates, bgColor }) => ({
    title,
    dates: dates.map(({ date }) => ({
      date: format(new Date(date.split("-").reverse().join("-")), "yyyy-MM-dd"), // Convert to YYYY-MM-DD
    })),
    bgColor,
  }));

export const EventsTimetable = () => {
  const { t } = useTranslation();

  const [showPastEvents, setShowPastEvents] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Translate events and group by date ranges
  const events = staticEvents.map((event) => ({
    ...event,
    title: t(event.title),
    description: t(event.description),
  }));

  const groupedEvents = groupByDateRanges(events);

  const renderTimelineElements = () => {
    return groupedEvents.map((event) => {
      const { start, end } = event.dateRange;
      const isSingleDay = start === end;
      const eventDateText = isSingleDay ? start : `${start} to ${end}`;

      const eventStartDate = new Date(start.split("-").reverse().join("-"));
      const isPastEvent = eventStartDate < currentDate;

      if (isPastEvent && !showPastEvents) {
        return null;
      }

      // MISSING PAST EVENTS CODE SNIPPET, import Tooltip

      return (
        <VerticalTimelineElement
          key={event.title + eventDateText}
          iconStyle={{ background: "#4caf50", color: "#fff" }}
          icon={event.icon}>
          <Grid container alignItems='center'>
            <Grid item md={8}>
              <Typography
                variant='h4'
                sx={{ background: event.bgColor }}
                padding={1}>
                {event.title}
              </Typography>
              <Typography variant='body1'>{eventDateText}</Typography>
              <Typography>{event.description}</Typography>
            </Grid>
            <Grid item md={4}>
              <img src={event.imageUrl} alt={event.title} width='100%' />
            </Grid>
          </Grid>
        </VerticalTimelineElement>
      );
    });
  };

  return (
    <Grid container>
      <Grid container justifyContent='center' padding={1}>
        <Button
          variant='contained'
          onClick={() => setShowPastEvents(!showPastEvents)}>
          {showPastEvents
            ? t("events.timetable.showLess")
            : t("events.timetable.showMore")}
        </Button>
      </Grid>
      <Grid container>
        <VerticalTimeline layout='1-column-left'>
          {renderTimelineElements()}
          <VerticalTimelineElement
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={<NewReleasesIcon />}
            date={t("events.timetable.more") || ""}
          />
        </VerticalTimeline>
      </Grid>
    </Grid>
  );
};
