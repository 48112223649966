import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const EventsInfo = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={"center"} padding={1}>
      <Grid item className='boxBorder' padding={1}>
        <Typography variant='h5'>{t("events.info.header.title")}</Typography>
        <Typography>{t("events.info.header.date")}</Typography>
        <Typography>{t("events.info.header.activities")}</Typography>
        <Typography>{t("events.info.header.more")}</Typography>
      </Grid>
    </Grid>
  );
};
