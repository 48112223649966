import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "DM-Mono, sans-serif",
    // Define font sizes using rem units
    fontSize: "1rem", // Set a base font size
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.9rem",
    },
    subtitle1: {
      fontSize: "0.5rem",
    },
    h3: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      fontSize: "2.5rem", // Initial font size (3rem * baseFontSize)
      "@media (max-width:600px)": { fontSize: "2rem" }, // Adjust for smaller screens
    },
    h4: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      fontSize: "2.2rem", // Initial font size (2.8rem * baseFontSize)
      "@media (max-width:600px)": { fontSize: "1.2rem" }, // Adjust for smaller screens
    },
    h5: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      fontSize: "1.6rem", // Initial font size (1.9rem * baseFontSize)
      "@media (max-width:600px)": { fontSize: "1rem" }, // Adjust for smaller screens
    },
    h6: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      fontSize: "1.2rem", // Initial font size (1.6rem * baseFontSize)
      "@media (max-width:600px)": { fontSize: "1rem" }, // Adjust for smaller screens
    },
    caption: {
      fontSize: "0.7rem", // Initial font size (0.875rem * baseFontSize)
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Use Typography component for button text with desired font size
          fontSize: "0.9rem", // Adjust the font size as needed
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem", // Set a minimum size for Material-UI icons
        },
      },
    },
  },
  palette: {
    background: {
      default: "#8bc34a",
    },
    primary: {
      main: "#4caf50", // Change the primary color
    },
    secondary: {
      main: "#ffea00", // Change the secondary color
    },
  },
});

// Dynamically adjust font size based on viewport width
const adjustFontSize = () => {
  const baseFontSize = 20; // Define your base font size in pixels (usually 16px)

  const viewportWidth = window.innerWidth;
  const calculatedFontSize = (viewportWidth / 375) * baseFontSize;
  const fontSize = `${Math.min(calculatedFontSize, baseFontSize)}px`;

  // Apply the calculated font size to the root element
  document.documentElement.style.fontSize = fontSize;
};

// Call the adjustFontSize function when the window loads
window.addEventListener('load', adjustFontSize);

// Add event listener for window resize (recalculating font size on resize)
window.addEventListener('resize', adjustFontSize);

export default theme;
