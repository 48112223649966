import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Typography, Grid, Button, Tooltip, Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AnimalIcon,
  BatIcon,
  BirdFlyIcon,
  BirdIcon,
  FrogIcon,
  LeafIcon,
} from "../../data/svgIcons";
import images from "../../data/images";
import BugReportIcon from "@mui/icons-material/BugReport";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import InfoIcon from "@mui/icons-material/Info";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { format } from "date-fns";

interface WorkshopDate {
  date: string;
  paymentUrl: string;
}

interface Workshop {
  title: string;
  dates: WorkshopDate[];
  description: string;
  icon: JSX.Element;
  imageUrl: string;
  type: String;
}

// Static workshops array
const staticWorkshops: Workshop[] = [
  // Adult Bird Workshop
  {
    title: "workshop.timetable.adultBirdDay.title",
    dates: [
      {
        date: "03-05-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-adultos-aves/107?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.adultBirdDay.description",
    icon: <BirdIcon />,
    imageUrl: images.nativeDay,
    type: "adult",
  },
  // Bird Workshop
  {
    title: "workshop.timetable.birdDay.title",
    dates: [
      {
        date: "04-08-2024",
        paymentUrl: "",
      },
      {
        date: "04-05-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-p-jaros-aut-ctonos/88?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.birdDay.description",
    icon: <BirdIcon />,
    imageUrl: images.nativeDay,
    type: "family",
  },
  // Adult Animal Workshop
  {
    title: "workshop.timetable.adultAnimalDay.title",
    dates: [
      {
        date: "01-11-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-adultos-nuestros-jardines/113?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.adultAnimalDay.description",
    icon: <AnimalIcon />,
    imageUrl: images.gardenDay,
    type: "adult",
  },
  // Animal Workshop
  {
    title: "workshop.timetable.animalDay.title",
    dates: [
      {
        date: "01-09-2024",
        paymentUrl: "",
      },
      {
        date: "02-11-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-naturaleza/89?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.animalDay.description",
    icon: <AnimalIcon />,
    imageUrl: images.natureDay,
    type: "family",
  },
  // Adult Insect Workshop
  {
    title: "workshop.timetable.adultInsectDay.title",
    dates: [],
    description: "workshop.timetable.adultInsectDay.description",
    icon: <BugReportIcon />,
    imageUrl: images.insectDay,
    type: "adult",
  },
  // Insect Workshop
  {
    title: "workshop.timetable.insectDay.title",
    dates: [
      {
        date: "02-06-2024",
        paymentUrl: "",
      },
      {
        date: "01-12-2024",
        paymentUrl: "",
      },
      {
        date: "01-06-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-insectos/90?cp=true&sa=true&sbp=false&q=false",
      },
      {
        date: "07-12-2025",
        paymentUrl: "",
      },
    ],
    description: "workshop.timetable.insectDay.description",
    icon: <BugReportIcon />,
    imageUrl: images.insectDay,
    type: "family",
  },
  // Frog Workshop
  {
    title: "workshop.timetable.frogDay.title",
    dates: [
      {
        date: "03-11-2024",
        paymentUrl: "",
      },
      {
        date: "07-07-2024",
        paymentUrl: "",
      },
      {
        date: "06-04-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-ranas/91?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.frogDay.description",
    icon: <FrogIcon />,
    imageUrl: images.pond,
    type: "family",
  },
  // Adult Organic Workshop
  {
    title: "workshop.timetable.adultOrganicDay.title",
    dates: [
      {
        date: "06-09-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-adultos-jardiner-a-ecol-gica/110?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.adultOrganicDay.description",
    icon: <LeafIcon />,
    imageUrl: images.organicDay,
    type: "adult",
  },
  // Organic Workshop
  {
    title: "workshop.timetable.organicDay.title",
    dates: [
      {
        date: "06-10-2024",
        paymentUrl: "",
      },
      {
        date: "07-09-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-cultivo-org-nico/95?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.organicDay.description",
    icon: <LeafIcon />,
    imageUrl: images.organicDay,
    type: "family",
  },
  // Bat Workshop
  {
    title: "workshop.timetable.batDay.title",
    dates: [
      {
        date: "03-08-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-murcielagos/109?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.batDay.description",
    icon: <BatIcon />,
    imageUrl: images.batDay,
    type: "family",
  },
  // Adult Migratory Workshop
  {
    title: "workshop.timetable.adultMigratoryDay.title",
    dates: [
      {
        date: "04-10-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-adultos-aves-migratorias/111?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.adultMigratoryDay.description",
    icon: <BirdFlyIcon />,
    imageUrl: images.migratoryDay,
    type: "adult",
  },
  // Migratory Workshop
  {
    title: "workshop.timetable.migratoryDay.title",
    dates: [
      {
        date: "05-10-2025",
        paymentUrl:
          "https://taller-eljardidelsocells.square.site/product/taller-aves-migratorias/112?cp=true&sa=true&sbp=false&q=false",
      },
    ],
    description: "workshop.timetable.migratoryDay.description",
    icon: <BirdFlyIcon />,
    imageUrl: images.migratoryDay,
    type: "family",
  },
];

export const getWorkshopData = () =>
  staticWorkshops.map(({ title, dates, type }) => ({
    title,
    dates: dates.map(({ date, paymentUrl }) => ({
      date: format(new Date(date.split("-").reverse().join("-")), "yyyy-MM-dd"), // Convert to YYYY-MM-DD
      paymentUrl,
    })),
    type,
  }));

export const WorkshopTimetable = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false); // Modal open state
  const [currentPaymentUrl, setCurrentPaymentUrl] = useState(""); // Store the current payment URL

  const handleOpen = (url: string) => {
    setCurrentPaymentUrl(url); // Set the current payment URL when the modal opens
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
  };

  // State to manage the visibility of past events
  const [showPastEvents, setShowPastEvents] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight

  const formatDateForCalendar = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  // Translate static workshop data
  const workshops = staticWorkshops.map((workshop) => ({
    ...workshop,
    title: t(workshop.title),
    description: t(workshop.description),
  }));

  // Flatten and sort the events by date
  const sortedEvents = workshops
    .flatMap((workshop, index) =>
      workshop.dates.map((dateInfo) => ({
        ...workshop,
        ...dateInfo,
        key: `${index}-${dateInfo.date}`,
      }))
    )
    .sort((a, b) => {
      const dateA = new Date(a.date.split("-").reverse().join("-")).getTime();
      const dateB = new Date(b.date.split("-").reverse().join("-")).getTime();
      return dateA - dateB;
    });

  const maxPastEvents = 5;
  const renderTimelineElements = () => {
    const pastEvents = sortedEvents.filter((event) => {
      const [day, month, year] = event.date.split("-");
      const eventDate = new Date(`${year}-${month}-${day}`);
      return eventDate < currentDate;
    });

    const recentPastEvents = pastEvents.slice(-maxPastEvents); // Get the last 5 past events

    const futureEvents = sortedEvents.filter((event) => {
      const [day, month, year] = event.date.split("-");
      const eventDate = new Date(`${year}-${month}-${day}`);
      return eventDate >= currentDate;
    });

    const visibleEvents = showPastEvents
      ? [...recentPastEvents, ...futureEvents]
      : futureEvents;

    return visibleEvents.map((event) => {
      const [day, month, year] = event.date.split("-");
      const eventDate = new Date(`${year}-${month}-${day}`);
      const isPastEvent = eventDate < currentDate;

      //console.log(`Event: ${event.title} Date: ${event.date} Payment URL: ${event.paymentUrl}`); // Add logging

      if (isPastEvent) {
        return (
          <VerticalTimelineElement
            key={`${event.key}-past`}
            contentStyle={{ padding: 0, paddingTop: 2 }}
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={event.icon}>
            <Typography variant='body1'>
              {t("workshop.timetable.past")}:{" "}
              <b>
                {event.title}
                <Tooltip arrow title={event.description}>
                  <InfoIcon />
                </Tooltip>
              </b>{" "}
              {event.date}
            </Typography>
          </VerticalTimelineElement>
        );
      } else {
        return (
          <VerticalTimelineElement
            key={`${event.key}-future`}
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={event.icon}>
            <Grid container alignItems='center'>
              <Grid item md={8}>
                <Typography variant='h4'>{event.title}</Typography>
                <Grid container justifyContent='center' paddingTop={1}>
                  {event.paymentUrl ? (
                    <>
                      <Grid item md={6}>
                        <Typography>{event.date}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <AddToCalendarButton
                          name={event.title}
                          description={event.description}
                          startDate={formatDateForCalendar(event.date)}
                          location='El Jardi Dels Ocells'
                          startTime='11:30'
                          endTime='12:45'
                          options={["Google", "Apple"]}
                          timeZone='Europe/Madrid'
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Typography>{event.date}</Typography>
                    </Grid>
                  )}
                </Grid>
                <Typography>{event.description}</Typography>
                {event.paymentUrl ? (
                  <>
                    <Button
                      sx={{ margin: 1, padding: 1 }}
                      variant='contained'
                      onClick={() => handleOpen(event.paymentUrl)}>
                      {event.title} {t("workshop.timetable.payment")}
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-describedby='payment-warning'>
                      <Box
                        sx={{
                          backgroundColor: "white",
                          padding: 2,
                          maxWidth: 400,
                          margin: "auto",
                          marginTop: "15%",
                          textAlign: "center",
                        }}>
                        <Typography
                          id='payment-warning'
                          variant='body1'
                          color='textSecondary'>
                          {t("workshop.timetable.warningMessage")}
                        </Typography>
                        <Button
                          sx={{ marginTop: 2 }}
                          variant='contained'
                          onClick={() => {
                            window.open(currentPaymentUrl, "_blank"); // Use current payment URL
                            handleClose();
                          }}>
                          {t("workshop.timetable.proceed")}
                        </Button>
                      </Box>
                    </Modal>
                  </>
                ) : (
                  <Typography variant='body1' color='red'>
                    <b>{t("workshop.timetable.paymentSoon")}</b>
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} paddingTop={2}>
                <img src={event.imageUrl} alt={event.title} width='100%' />
              </Grid>
            </Grid>
          </VerticalTimelineElement>
        );
      }
    });
  };

  return (
    <Grid container>
      <Grid container justifyContent='center'>
        <Button
          variant='contained'
          onClick={() => setShowPastEvents(!showPastEvents)}>
          {showPastEvents
            ? t("workshop.timetable.showLess")
            : t("workshop.timetable.showMore")}
        </Button>
      </Grid>
      <Grid container>
        <VerticalTimeline layout='1-column-left'>
          {renderTimelineElements()}
          <VerticalTimelineElement
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={<NewReleasesIcon />}
            date={t("workshop.timetable.more") || ""}
          />
        </VerticalTimeline>
      </Grid>
    </Grid>
  );
};
