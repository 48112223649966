import { Grid, Typography, Button, IconButton } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import images from "../../data/images";

//icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
//import images from "../../data/images";

export const Carousel = () => {
  const { t, i18n } = useTranslation();
  // const [isWithinDateRange, setIsWithinDateRange] = useState(false);

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const startDate = new Date("2024-03-08");
  //   const endDate = new Date("2024-04-01");
  //   setIsWithinDateRange(currentDate >= startDate && currentDate <= endDate);
  // }, []);

  const AdditionalBox = () => {
    return (
      <>
        <Grid className='boxCaroselBottom'>
          <Grid className='carouselBorder' padding={"10px"}>
            <Grid className='centerRow'>
              <Grid item width={"33%"}>
                <Link
                  to={`/${i18n.language}/planYourVisit#calendar`}
                  aria-label={t("carousel.openTime").toString()}>
                  <IconButton aria-label={t("carousel.openTime").toString()}>
                    <CalendarMonthOutlinedIcon />
                  </IconButton>
                  <Typography variant='body2'>
                    {t("carousel.openTime")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item width={"33%"}>
                <Link
                  to={`/${i18n.language}/planYourVisit#prices`}
                  aria-label={t("carousel.prices").toString()}>
                  <IconButton aria-label={t("carousel.prices").toString()}>
                    <EuroOutlinedIcon />
                  </IconButton>
                  <Typography variant='body2'>
                    {t("carousel.prices")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item width={"33%"}>
                <Link
                  to={`/${i18n.language}/planYourVisit#map`}
                  aria-label={t("carousel.getHere").toString()}>
                  <IconButton aria-label={t("carousel.getHere").toString()}>
                    <LocationOnOutlinedIcon />
                  </IconButton>
                  <Typography variant='body2'>
                    {t("carousel.getHere")}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTextAndButton = (
    title: string,
    titleSub: string,
    buttonLabel: string,
    link: string
  ) => {
    return (
      <Grid className='boxCaroselTop'>
        <Grid className='carouselBorderTop' padding={"10px"}>
          <Grid container className='center' direction={"column"}>
            <Typography variant='h5'>{title}</Typography>
            <Typography>{titleSub}</Typography>
            {buttonLabel && (
              <Link to={`/${i18n.language}${link}`}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ padding: 5 }}
                  aria-label={buttonLabel}>
                  <Typography variant='body2'>{buttonLabel}</Typography>
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid marginBottom={-1}>
      <Swiper
        rewind={true}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        style={{ height: "100%" }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, Navigation]}>
        {/* Easter
        {isWithinDateRange && (
          <SwiperSlide>
            <Grid>
              <img
                src={
                  window.innerWidth < 800
                    ? require("../../images/birdArea-sm.jpg")
                    : require("../../images/birdArea.jpg")
                }
                alt='birdArea'
                className='carouselImg'
              />
              {renderTextAndButton(
                t("carousel.easter"),
                t("carousel.easterSub"),
                t("carousel.easterButton"),
                "/easter"
              )}
              <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
                <AdditionalBox />
              </Grid>
            </Grid>
          </SwiperSlide>
        )} */}

        {/* Workshop 
        <SwiperSlide>
          <Grid>
            <img src={images.animalDay} alt='map' className='carouselImg' />
            {renderTextAndButton(
              t("carousel.workshop"),
              t("carousel.workshopSub"),
              t("carousel.workshopButton"),
              "/workshop"
            )}
            <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
              <AdditionalBox />
            </Grid>
          </Grid>
        </SwiperSlide>*/}

        {/* Gift Card */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/Gift_Cards-sm.jpg")
                  : require("../../images/Gift_Cards.jpg")
              }
              alt='giftCards'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.giftcard"),
              t("carousel.giftcardSub"),
              t("carousel.giftcardButton"),
              "/giftCard"
            )}
            <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
              <AdditionalBox />
            </Grid>
          </Grid>
        </SwiperSlide>

        {/* Map */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/mapPark-sm.jpg")
                  : require("../../images/mapPark.jpg")
              }
              alt='map'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.map"),
              t("carousel.mapSub"),
              t("carousel.mapButton"),
              "/map"
            )}
            <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
              <AdditionalBox />
            </Grid>
          </Grid>
        </SwiperSlide>

        {/* Year Pass */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800 ? images.paseAnualSm : images.paseAnual
              }
              alt='pase_anual'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.yearpass"),
              t("carousel.yearpassSub"),
              t("carousel.yearpassButton"),
              "/yearPass"
            )}
            <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
              <AdditionalBox />
            </Grid>
          </Grid>
        </SwiperSlide>

        {/* Shop 
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/shop-sm.jpg")
                  : require("../../images/shop.jpg")
              }
              alt='shop'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.shop"),
              t("carousel.shopSub"),
              t(""),
              ""
            )}
            <Grid sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
              <AdditionalBox />
            </Grid>
          </Grid>
        </SwiperSlide>*/}
      </Swiper>
      <Grid sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <AdditionalBox />
      </Grid>
    </Grid>
  );
};
