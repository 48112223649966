import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { prices } from "../../data/data";

export const WorkshopInfo = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={"center"} padding={1}>
      <Grid item className='boxBorder' padding={1}>
        <Typography variant='h5'>{t("workshop.info.header.title")}</Typography>
        <Typography>{t("workshop.info.header.date")}</Typography>
        <Typography>{t("workshop.info.header.activities")}</Typography>
        <Typography>{t("workshop.info.header.price")}</Typography>
        <Typography>{t("workshop.info.header.more")}</Typography>
      </Grid>
      <Grid container padding={0}>
        <Grid item md={6} xs={12} paddingTop={2}>
          <Typography variant='h5'>
            {t("workshop.info.layout.title")}
          </Typography>
          <TableContainer
            component={Box}
            sx={{
              overflowX: "auto", // Enable horizontal scrolling
              maxWidth: "100%", // Ensure it fits the container
            }}>
            <Table
              sx={{
                "& .MuiTableCell-root": {
                  paddingLeft: "4px",
                  paddingRight: "4px",
                },
              }}>
              <TableBody>
                <TableRow>
                  <TableCell>{t("workshop.info.layout.time.title")}:</TableCell>
                  <TableCell>{t("workshop.info.layout.time.info")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("workshop.info.layout.lang.title")}:</TableCell>
                  <TableCell>{t("workshop.info.layout.lang.info")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.layout.capacity.title")}:
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("workshop.info.layout.capacity.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.layout.inscription.title")}:
                  </TableCell>
                  <TableCell>
                    {t("workshop.info.layout.inscription.info")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={6} xs={12} paddingTop={2}>
          <Typography variant='h5'>
            {t("workshop.info.prices.title")}
          </Typography>
          <TableContainer
            component={Box}
            sx={{
              overflowX: "auto", // Enable horizontal scrolling
              maxWidth: "100%", // Ensure it fits the container
            }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.prices.price.title")}:
                  </TableCell>
                  <TableCell>
                    {prices.workshop + t("workshop.info.prices.price.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.prices.price2.title")}:
                  </TableCell>
                  <TableCell>
                    {prices.workshopBundle +
                      t("workshop.info.prices.price2.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.prices.condition.title")}:
                  </TableCell>
                  <TableCell>
                    <b>{t("workshop.info.prices.condition.family.title")}: </b>
                    {t("workshop.info.prices.condition.family.info")}
                    <br></br>
                    <b>{t("workshop.info.prices.condition.adult.title")}: </b>
                    {t("workshop.info.prices.condition.adult.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.prices.discount.title")}:
                  </TableCell>
                  <TableCell>
                    {t("workshop.info.prices.discount.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("workshop.info.prices.note.title")}:</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {t("workshop.info.prices.note.info")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("workshop.info.prices.returns.title")}:
                  </TableCell>
                  <TableCell>
                    {t("workshop.info.prices.returns.info")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};
